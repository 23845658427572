<script>
  // @ts-nocheck

  import { onMount } from 'svelte';
  import ButtonMenu from '../lib/components/buttonMenu.svelte';
  import LoadBar from '../lib/components/loadBar.svelte';
  import { DataStore } from '$lib/firebase/datastore';
  import InfoBox from '$lib/components/infoBox.svelte';
  import { createAnonUser } from '$lib/ts/createAnonUser';
  import { MetaWeb } from '$lib/metaweb/metaweb';
  import Accountreminder from '$lib/components/accountreminder.svelte';
  let loading = true;
  let loggedIn = false;
  let error = '';

  let user; // user or null

  function buttonClicked(event) {
    try {
      loading = true;
      const key = event.detail.key;
      window.location.href = `${key}`;
    } catch (e) {
      error = e;
    }
  }

  let dataStore;
  try {
    dataStore = new DataStore();
  } catch (e) {
    error = 'Failed to init dataStore';
  }
  onMount(async () => {
    loading = true;
    loggedIn = false;
    const metaWeb = await MetaWeb.initialize();
    user = await metaWeb.getCurrentUser();
    try {
      if (user?.id) {
        loggedIn = true;
      } else {
        const anonymousLogIn = await createAnonUser();
        window.location.reload();
      }
    } catch (e) {
      error = 'Failed to check login status';
    }
    if (loggedIn) {
      loading = false;
    } else {
      window.location.reload();
    }
  });
</script>

<svelte:head>
  <title>Metaweb</title>
</svelte:head>

<main>
  <div class="container">
    {#if user?.isAnon}
      <Accountreminder />
    {/if}
    <div class="mb-10">
      <center>
        {#if user?.userName && !user?.isAnon}
          <p class="text-blue-600 text-2xl">Tervetuloa {user.userName}!</p>
        {:else if user?.isAnon}
          <a href="/profile/claimAccount" class="text-red-600 text-xl">Ei käyttäjää!</a>
        {/if}
      </center>
    </div>
    <ButtonMenu
      buttonObj={[
        ['Valitse sijainti käyttämällä GPS', '/gps'],
        ['Skannaa QR koodi', '/qr'],
        ['Katso tokeneitasi', '/collection'],
        ['Lähetä tokeneita', '/sendToken'],
        ['Luo tokeneita', '/admin'],
        ['Profiilisi', '/profile'],
      ]}
      on:buttonClick={buttonClicked}
    />
    <br />
  </div>
  {#if user?.isAnon}
    <div class="text-white text-2xl fixed bottom-0 right-0 w-screen flex flex-row">
      <div class="flex flex-col gap-5">
        <ButtonMenu
          buttonObj={[['Kirjaudu sisään', '/profile/claimAccount']]}
          on:buttonClick={buttonClicked}
        />
      </div>
    </div>
  {/if}
</main>
{#if error}
  <div class="container">
    <InfoBox
      header="Virhe"
      message={error}
      color="#FF0000"
      {loading}
      on:closed={() => {
        loading = true;
        error = '';
        window.location.reload();
      }}
    />
  </div>
{/if}

{#if loading}
  <LoadBar absolute={true} />
{/if}

<style>
  .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
