<script>
  import { onMount } from 'svelte';
  import InfoBox from './infoBox.svelte';
  import { setCookie, getCookie } from '$lib/ts/cookieMethods';

  let shouldShow = false;

  const cookie_key = 'accountreminder_dismissed';
  onMount(() => {
    const dismissed = getCookie(cookie_key);
    if (!dismissed) shouldShow = true;
  });
</script>

{#if shouldShow}
  <InfoBox
    header="Sinulla ei ole käyttäjää"
    message="Haluatko kirjautua?"
    primaryButton="Ei kiitos"
    secondaryButton="Kyllä"
    on:closed={(event) => {
      const buttonNumber = event.detail.button;
      if (buttonNumber === 2) {
        window.location.href = '/profile';
      } else {
        // User dismissed
        setCookie(cookie_key, 'true');
      }
    }}
  />
{/if}
